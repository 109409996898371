<template>
    <div>
        <b-button variant="dark" :disabled="siteData != null && siteData != 'all-site'  ? false:true" class="mobile-margin-bottom mr-1" @click="openModel">
            <span class="text-nowrap">Export Yearly Report</span>
        </b-button>

        <b-modal
            id="group_export" ref="group_export" cancel-variant="outline-secondary" ok-variant="outline-warning" ok-title="Submit" 
            cancel-title="Close" centered title="Export Yearly Report" @ok="exportData" no-close-on-backdrop
        >
            <b-alert v-model="popshowDismissibleAlert" variant="danger" dismissible class="mb-1">
                <div class="alert-body">{{error_message}}</div>
            </b-alert>

            <b-form>
                <b-row>
                    <b-col md="12">
                        <b-form-group label="Generate For The Year" class="required">
                            <v-select
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="years"
                                class="w-100"
                                placeholder="Select Year"
                                v-model="year"
                                :clearable="false"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>

      </b-modal>
    </div>
</template>
<script>
    import {
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BAlert,BFormGroup,
        BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox, BCardBody, BFormSelectOption,
        BFormSelect, BFormTextarea, BForm, BFormDatepicker, BFormRadio, BBreadcrumb
    } from 'bootstrap-vue';
    import { ref, onUnmounted } from '@vue/composition-api';
    import { GET_API,POST_API } from "../../../store/actions.type";
    import Datepicker from 'vuejs-datepicker';
    import moment from "moment-timezone";
    import { $themeColors } from '@themeConfig';
    import vSelect from 'vue-select'

    export default {
        name: 'ExportYearlyExcelReport',
        components: {
            Datepicker, BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown,
            BDropdownItem, BPagination, BFormCheckbox, BFormSelect, BFormSelectOption, BFormTextarea, vSelect,
            BAlert, BFormGroup, BForm, BFormDatepicker, BFormRadio, BBreadcrumb
        },
        props: ['siteData', 'site_name', 'group'],
        data() {
            return {
                webUrl : process.env.VUE_APP_SERVER_URL,
                popshowDismissibleAlert : false,
                error_message : '',

                current_Year : moment().format('YYYY'),
                years        : [],
                year         : moment().format('YYYY')
            }
        },

        methods : {
            calYear(){  
                var year = parseInt(moment().format('YYYY')) - 1;
                
                this.years = [];
                var i = 0;
                while(i<=2){
                    this.years.push(year);
                    year = year +1
                    i = i+1;
                }
            },
                
            openModel(item){
                if(this.group.length > 0){
                    this.popshowDismissibleAlert = false;
                    this.year = moment.tz('Asia/Singapore').format('YYYY');
                    this.$refs['group_export'].show();
                } else {
                    this.customAlert('No Group Found for this Project Site.', '', 'warning');
                }
            },

            exportData(bvModalEvt){
                bvModalEvt.preventDefault();

                return this.$store.dispatch(POST_API, {
                    data:{
                        site : this.siteData,
                        year : this.year,
                        report : 'periodic',
                    },
                    api: '/api/check-yearly-schedule-work'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.popshowDismissibleAlert = true;
                    } else {
                        this.popshowDismissibleAlert = false;

                        var message  = this.$store.getters.getResults.message;
                        var data  = this.$store.getters.getResults.data;
                        
                        if (data > 0) {
                            this.popshowDismissibleAlert = false;
                            this.$refs['group_export'].hide();
                            this.getYearlyReport();
                        }else{
                            this.error_message = 'No Records Found.';
                            this.popshowDismissibleAlert = true;
                        }
                    }
                });
            },

            getYearlyReport(){
                var site = this.siteData;
                var site_name = this.site_name;
                var year = this.year;
                var report = 'periodic';

                var params = "site="+site+"&site_name="+site_name+"&year="+year+"&report="+report+"&organization="+ this.$store.getters.currentUser.organization;

                var api = '/api/yearly-schedule-work-excel?';

                var baseurl = "";
                baseurl = process.env.VUE_APP_SERVER_URL + api + params;

                window.open(baseurl,'_blank');

                this.popshowDismissibleAlert = false;
                this.$refs['group_export'].hide();
            },
        },

        mounted(){
            this.calYear();
        }
    }
</script>
